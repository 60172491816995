import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-versions-section',
  templateUrl: './versions-section.component.html',
  styleUrls: ['./versions-section.component.css']
})
export class VersionsSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
